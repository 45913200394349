export default {
  NOT_SUBMITTED: {
    value: 'NOT_SUBMITTED',
    label: 'Not Submitted.',
  },
  SUBMITTED: {
    value: 'SUBMITTED',
    label: 'Submitted!',
  },
  APPLYING: {
    value: 'APPLYING',
    label: 'Applying.',
  },
  APPLIED: {
    value: 'APPLIED',
    label: 'Applied',
  },
  WAITLISTED: {
    value: 'WAITLISTED',
    label: 'Waitlisted.',
  },
  ACCEPTED: {
    value: 'ACCEPTED',
    label: 'Accepted!',
  },
  REJECTED: {
    value: 'REJECTED',
    label: 'Rejected.',
  },
  ACCEPTED_INVITE: {
    value: 'ACCEPTED_INVITE',
    label: 'Confirmed!',
  },
  REJECTED_INVITE: {
    value: 'REJECTED_INVITE',
    label: 'Declined.',
  },
  SCANNED_IN: {
    value: 'SCANNED_IN',
    label: 'Scanned In.',
  },
  WALK_IN: {
    value: 'WALK_IN',
    label: 'Walk in.',
  },
  WALK_IN_SUBMIT: {
    value: 'WALK_IN_SUBMIT',
    label: 'Walk in (Submitted).',
  },
};
